import { Link, navigate } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'
import { BreadCrumbItems } from '../../components/breadCrumb/LocalizedBreadCrumb'
import Button from '../../components/buttons/Button'
import CTACard from '../../components/CTACard/CTACard'
import CtaLink from '../../components/CtaLink/CtaLink'
import Layout from '../../components/Layout'
import RecomendacionCard from '../../../specific/components/RecomendacionCard/RecomendacionCard'
import Title from '../../components/Title'
import { findMenuEntry } from '../../../specific/constants/menu'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { t, textWithLocale } from '../../../common/i18n'
import '../../styles/singlePage.scss'
import route from '../../utils/route'
import styles from './plantillaLateral.module.scss'
import ButtonLink from '../../components/buttons/ButtonLink'

const convertToFirstUpperCase = (sentence) => {
  const lowercase = sentence ? sentence.toLowerCase() : ''
  return sentence ? sentence[0] + lowercase.slice(1) : ''
}

const PageTemplate = ({ data }) => {
  useSelectedMenu(data.plantillas.id_menu_principal)
  const breadCrumItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
  ] as BreadCrumbItems

  const principal = findMenuEntry(data.plantillas.id_menu_principal)
  if (principal) {
    breadCrumItems.push({
      display: t(principal.name),
      link: route(principal.route),
    })
  }

  if (data.plantillas.site_distributiva) {
    breadCrumItems.push({
      display: data.plantillas.site_distributiva[textWithLocale('titulo')],
      link: data.plantillas.site_distributiva[textWithLocale('nombre')],
    })
  }
  breadCrumItems.push({
    display: convertToFirstUpperCase(data.plantillas[textWithLocale('titulo')]),
  })

  const needsCtaButton = () => {
    if (
      data.plantillas.cta_descripcion_es &&
      data.plantillas.cta_descripcion_ca
    ) {
      return false
    }
    return true
  }

  return (
    <Layout
      selector={false}
      breadCrumItems={breadCrumItems}
      seoData={data.seoData}>
      <div className={styles.ficha_container}>
        <Title
          text={convertToFirstUpperCase(
            data.plantillas[textWithLocale('titulo')]
          )}
        />
        <img
          className={styles.ficha_imagen}
          src={data.plantillas[textWithLocale('image_url')]}
          alt="Imagen ficha"
        />
        {/* <div className={styles['ficha_imagen']} style={{ backgroundImage: `url(${data.plantillas[textWithLocale('image_url')]})` }}/> */}
        <div className={styles.ficha_container_inferior}>
          <div className={styles.ficha_wrapper_izquierda}>
            <div className={styles.ficha_container_izquierda}>
              <div
                className={styles.ficha_texto_container}
                dangerouslySetInnerHTML={{
                  __html: data.plantillas[textWithLocale('texto')],
                }}></div>
              {needsCtaButton() && data.plantillas.cta !== 'cita_previa' ? (
                <Button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(data.plantillas[textWithLocale('link_cta')])
                  }}>
                  {data.plantillas[textWithLocale('cta')]}
                </Button>
              ) : null}
              {needsCtaButton() &&
              data.plantillas.cta === 'cita_previa' &&
              data.plantillas.categoria !== 'moto' ? (
                <CtaLink
                  data={{
                    cta: data.plantillas.cta,
                    cta_es: data.plantillas.cta_es,
                    cta_ca: data.plantillas.cta_ca,
                    cta_pt: data.plantillas.cta_pt,
                  }}
                />
              ) : data.plantillas.categoria === 'moto' ? (
                <ButtonLink to={data.plantillas[textWithLocale('link_cta')]}>
                  {data.plantillas[textWithLocale('cta')]
                    ? data.plantillas[textWithLocale('cta')].toUpperCase()
                    : ''}
                </ButtonLink>
              ) : null}
            </div>
          </div>
          <div className={styles.ficha_container_lateral}>
            {data.plantillas[textWithLocale('recomendacion')] ? (
              <RecomendacionCard
                recomendacion={data.plantillas[textWithLocale('recomendacion')]}
              />
            ) : null}
            {data.plantillas.cta_descripcion_es !== '' &&
            data.plantillas.cta_descripcion_es !== '' ? (
              <CTACard
                description={data.plantillas[textWithLocale('cta_descripcion')]}
                linkButton={data.plantillas[textWithLocale('link_cta')]}
                textButton={
                  data.plantillas[textWithLocale('cta')]
                    ? data.plantillas[textWithLocale('cta')].toUpperCase()
                    : ''
                }
                ctaLink={
                  (data.plantillas.cta === 'cita_previa' ||
                    data.plantillas.cta === 'rodibook') &&
                  data.plantillas.categoria !== 'moto'
                    ? {
                        cta: data.plantillas.cta,
                        cta_es: data.plantillas.cta_es,
                        cta_ca: data.plantillas.cta_ca,
                        cta_pt: data.plantillas.cta_pt,
                      }
                    : null
                }
              />
            ) : null}
            {data.plantillas.Relacionadas.length > 0 ? (
              <div className={styles.ficha_leer_mas_container}>
                {t('interesar')}
                {data.plantillas.Relacionadas.map((recomendacion, index) => (
                  <div key={index} className={styles.ficha_leer_mas}>
                    <div className={styles.read_more_pic_container}>
                      <img
                        className={styles.read_more_pic}
                        src={recomendacion[textWithLocale('miniatura_url')]}
                        alt="Imagen ficha"
                      />
                    </div>
                    <div className={styles.read_more_right}>
                      <div className={styles.read_more_texts}>
                        <h3>{recomendacion[textWithLocale('titulo')]}</h3>
                        <div className={styles.read_more_summary}>
                          {recomendacion[textWithLocale('resumen')]}
                        </div>
                      </div>
                      <Link to={recomendacion[textWithLocale('nombre')]}>
                        <button className={styles.leer_mas_boton}>
                          {t('leer_mas')}
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query plantillaLateralPageQuery($id_site_plantilla: Int!, $url: String!) {
    plantillas(id_site_plantilla: { eq: $id_site_plantilla }) {
      id_site_plantilla
      id_menu_principal
      nombre
      nombre_es
      nombre_ca
      nombre_pt
      tipo
      titulo_es
      titulo_ca
      titulo_pt
      texto_ca
      texto_es
      texto_pt
      image_url_es
      image_url_ca
      image_url_pt
      recomendacion_ca
      recomendacion_es
      recomendacion_pt
      categoria
      categoria_es
      categoria_ca
      categoria_pt
      site_distributiva {
        id_site_distributiva
        nombre_es
        nombre_ca
        nombre_pt
        titulo_ca
        titulo_es
        titulo_pt
      }
      cta
      image_url_mobile_es
      image_url_mobile_ca
      image_url_mobile_pt
      cta_descripcion_es
      cta_descripcion_ca
      cta_descripcion_pt
      cta_es
      cta_ca
      cta_pt
      link_cta_es
      link_cta_ca
      link_cta_pt
      Relacionadas {
        id_site_plantilla
        titulo_es
        titulo_ca
        titulo_pt
        texto_ca
        texto_es
        texto_pt
        image_url_es
        image_url_ca
        image_url_pt
        resumen_ca
        resumen_es
        resumen_pt
        nombre_ca
        nombre_es
        nombre_pt
        miniatura_url_es
        miniatura_url_ca
        miniatura_url_pt
      }
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
